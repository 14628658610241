import { merge as _merge, get as _get } from 'lodash-es';

import General from './General';
import { _selector, _pixelToEm } from './helpers';

export default (element, template) => {
  const { uid, type } = element;

  const selector = `${_selector(uid, type)} .om-product-holder`;
  const { desktop, mobile } = General(element, template, selector);

  const smartSizeType = _get(element, 'desktop.smartSize.type', 'fluid');
  const nrOfProducts = _get(element, 'data.nrOfProducts', 1);
  if (smartSizeType === 'fluid') {
    const marginHorizontal = element.desktop.margin.left + element.desktop.margin.right;
    const holderWidth =
      template.style.mode === 'nano'
        ? 'auto'
        : `calc(100% / ${nrOfProducts} - ${
            nrOfProducts === 1 ? 1 : nrOfProducts - 1
          } * ${_pixelToEm(marginHorizontal)}em)`;

    _merge(desktop, {
      [selector]: {
        width: { v: holderWidth, i: false },
      },
    });
  }

  const marginMobileHorizontal =
    (element.mobile.margin.left || element.desktop.margin.left) +
    (element.mobile.margin.right || element.desktop.margin.right);
  const holderWidthOnMobile = `calc(100% - ${_pixelToEm(marginMobileHorizontal)}em)`;
  // Product components are horizontally scrollable on mobile devices, no need to limit their width.
  _merge(mobile, {
    [selector]: {
      width: { v: holderWidthOnMobile, i: true },
    },
  });

  return {
    desktop,
    mobile,
  };
};
