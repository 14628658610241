import { get as _get, set as _set, merge as _merge } from 'lodash-es';
import General from './General';
import { _selector, fontSize, fontFamily, _pixelToEm } from '../helpers';

export default (subElement, subElementType, element, template) => {
  const { desktop, mobile } = General(subElement, subElementType, element, template);

  const { uid, type } = element;
  const baseSelector = _selector(uid, type);
  const selector = `${baseSelector} ${subElement.selector}`;

  if (['number', 'separator', 'label'].includes(subElementType)) {
    let desktopFontSizeValue = _get(element, 'subElements.shared.desktop.fontSize');
    const mobileFontSizeValue =
      _get(element, 'subElements.shared.mobile.fontSize') || desktopFontSizeValue;

    if (subElementType === 'label') {
      if (desktopFontSizeValue > 48) {
        desktopFontSizeValue = `${_pixelToEm(desktopFontSizeValue / 2.125)}em`;
      } else {
        desktopFontSizeValue = '1em';
      }

      _set(desktop, `["${selector}"].font-size.v`, desktopFontSizeValue);
      _set(mobile, `["${selector}"].font-size.v`, `${_pixelToEm(mobileFontSizeValue)}em`);
    } else if (subElementType === 'number') {
      _merge(
        desktop,
        fontSize(uid, type, desktopFontSizeValue, false, template, `${selector} span`),
      );
      _merge(mobile, fontSize(uid, type, mobileFontSizeValue, false, template, `${selector} span`));
    } else {
      _merge(desktop, fontSize(uid, type, desktopFontSizeValue, false, template, selector));
      _merge(mobile, fontSize(uid, type, mobileFontSizeValue, false, template, selector));
    }
  }

  if (['number', 'label'].includes(subElementType)) {
    const desktopFontFamily = _get(element, 'subElements.shared.desktop.fontFamily');
    const mobileFontFamily =
      _get(element, 'subElements.shared.mobile.fontFamily') || desktopFontFamily;

    _merge(desktop, fontFamily(uid, type, desktopFontFamily, false, template, selector));
    _merge(mobile, fontFamily(uid, type, mobileFontFamily, false, template, selector));
  }

  return {
    desktop,
    mobile,
  };
};
